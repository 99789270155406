<!-- 
	This is an empty page for layout demonstrations, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
    <div>

        <vue-loaders-ball-beat v-if="loading" color="red" scale="1"></vue-loaders-ball-beat>

        <a-row>
            <a-col :span="24" class="mb-24">
                <h4 class="mb-15">{{ name }}</h4>
                <h6 class="font-regular text-muted">{{ description }}</h6>
            </a-col>
        </a-row>
 
        <a-row type="flex" :gutter="24">
            <a-col :span="12" :xl="6" class="mb-24" v-for="(stat, index) in general_stats" :key="index">

                <!-- Salary Card -->
                <WidgetStats :value="stat.value" :prefix="stat.prefix" :icon="stat.icon" :title="stat.title"
                    :content="stat.content"></WidgetStats>
                <!-- / Salary Card -->
            </a-col>
        </a-row>

        <a-tabs default-active-key="1" v-if="channel_type == 'whatsapp'">

            <a-tab-pane key="1" tab="Live Chat" force-render>

                <a-row type="flex" :gutter="24">
                        <a-col :span="24"  class="mb-24">
 
                            <SimpleChat :currentUserId="currentUserId" :rooms="rooms" :messages="messages"
                        :roomActions="roomActions" :channelId="$route.params.id" :searchLoading="searchLoading" :onSearch="onSearch" :searchTerm="searchTerm" :channelProvider="channel_provider"/>
                        </a-col>
                </a-row>
                
            </a-tab-pane>

            <a-tab-pane key="2" tab="Broadcast" force-render>

                Here you can broadcast or schedule messages to your customers.

            </a-tab-pane>

            <a-tab-pane key="3" tab="Setup">
                <div>


                    <a-row type="flex" :gutter="24">
                        <a-col :span="24" :xl="12" class="mb-24">

                            <!-- Master Card -->
                            <WidgetWhatsappChannelSetupVue :channel_data="channel_data" :channelId="$route.params.id">


                            </WidgetWhatsappChannelSetupVue>
                            <!-- / Master Card -->



                        </a-col>

                        <a-col :span="24" :xl="12" class="mb-24">

                            <a-row>

                                <a-card title="Update Setup">

                                    <p>
                                        You can update the configuration of this channel.
                                        Once set up, you can start using your channel. You need to configure it before you can start responding to messages.
                                    </p>

                                    <br>
                                    <a-row>
                                        <vue-loaders-ball-beat v-if="processing" color="green"
                                            scale="1"></vue-loaders-ball-beat>
                                        <a-button type="danger" @click="saveChannelData">Update Configuration</a-button>
                                    </a-row>

                                    <a-row>
                                    </a-row>
                                </a-card>
                            </a-row>
                        </a-col>
                    </a-row>

                </div>

            </a-tab-pane>

            <a-tab-pane key="4" tab="AI & Bots">

            </a-tab-pane>

            

        </a-tabs>



        <a-tabs default-active-key="1" v-if="channel_type == 'voice'">

            <a-tab-pane key="1" tab="Call Recordings" force-render>
                Nothing to show yet
            </a-tab-pane>

            <a-tab-pane key="2" tab="Setup">
                <div>


                    <a-row type="flex" :gutter="24">
                        <a-col :span="24" :xl="12" class="mb-24">

                            <!-- Master Card -->
                            <WidgetVoiceChannelSetup :channel_data="channel_data">


                            </WidgetVoiceChannelSetup>
                            <!-- / Master Card -->



                        </a-col>

                        <a-col :span="24" :xl="12" class="mb-24">

                            <a-row>

                                <a-card title="Update Setup">

                                    <p>
                                        You can update the configuration of this channel.
                                        Once set up, you can start using your channel. This will not affect your currently
                                        opened calls.
                                    </p>

                                    <a-row>
                                        <a-switch v-model="channel_data.record_calls" /> <span>Record Calls</span>

                                    </a-row>

                                    <a-row>

                                        <a-switch v-model="channel_data.say_welcome_message" :disabled="processing" />
                                        <span>Say Welcome Message</span>

                                        <br><br>

                                        <a-form-item label="Welcome Message" class="mb-5" :colon="false">
                                            <a-input v-model="channel_data.welcome_message" :disabled="processing" />
                                        </a-form-item>



                                    </a-row>

                                    <br>
                                    <a-row>
                                        <vue-loaders-ball-beat v-if="processing" color="green"
                                            scale="1"></vue-loaders-ball-beat>
                                        <a-button type="danger" @click="saveChannelData">Update Configuration</a-button>
                                    </a-row>


                                </a-card>


                            </a-row>

                            <br>

                            <a-row>

                                <a-card title="Receive Calls">

                                    <p>
                                        You can enable a call listener to this channel so you can receive calls from your
                                        customers.
                                        Once set up, this will open a new tab with the call interface set up. In case of an
                                        incoming call, you will receive an incoming call sound.
                                    </p><br><br>

                                    <vue-loaders-ball-beat v-if="listenProcessing" color="green"
                                        scale="1"></vue-loaders-ball-beat>

                                    <a-row>
                                        <a-col :span="16">
                                            <a-button type="primary" @click="sendChannelListenRequest">Start Receiving
                                                Calls</a-button>
                                        </a-col>



                                    </a-row>


                                </a-card>


                            </a-row>







                        </a-col>


                    </a-row>

                </div>
            </a-tab-pane>
            
            <a-tab-pane key="3" tab="About">
                SemaHub voice channel
            </a-tab-pane>
        </a-tabs>

    </div>
</template>

<script>

import { register } from 'vue-advanced-chat'
  register()

// "Projects" table component.
import WidgetStats from '../components/Widgets/WidgetStats';

import WidgetVoiceChannelSetup from '../components/Widgets/WidgetVoiceChannelSetup';
 
import WidgetWhatsappChannelSetupVue from '../components/Widgets/WidgetWhatsappChannelSetup.vue';

import SimpleChat from '../components/Chat/SimpleChat';
 
import { fetchDataJSON, fetchPostJSON } from '../services/v1/services';

export default ({
    components: {
        WidgetStats,
        WidgetVoiceChannelSetup,
        SimpleChat,
        WidgetWhatsappChannelSetupVue,
    },
    async mounted() {
        // ${this.$route.params.id}

        const channelRes = await fetchDataJSON('projects/channels/' + this.$route.params.id + '/info', true);

        this.channel_data = channelRes.data.channel_data;

        this.general_stats = channelRes.data.stats.general_stats;

        this.name = channelRes.data.name;

        this.description = channelRes.data.description;

        this.channel_type = channelRes.data.channel_type;

        this.channel_provider = channelRes.data.channel_provider;

        this.channel_data = channelRes.data.channel_data;

        if (this.channel_data.say_welcome_message == null) {
            this.channel_data.say_welcome_message = false;
        }

        if (this.channel_data.record_calls == null) {
            this.channel_data.record_calls = false;
        }

        if (this.channel_data.welcome_message == null) {
            this.channel_data.welcome_message = '';
        }

        this.loading = false;

        this.$eventHub.$on("newCallDeviceRequestEnded", async (item) => {
            console.log('call device data indir', item);

            this.listenProcessing = false;

        });

        this.$eventHub.$on("newWhatsAppMessage", async (item) => {

            const roomsRes = await fetchDataJSON('projects/channels/' + this.$route.params.id + '/whatsapp/rooms/list', true);

            this.rooms = roomsRes.data.rooms;

        });


        this.$eventHub.$on("channelSettingsUpdate", async (item) => { 

            console.log('channelSettingsUpdate');
            
            const channelRes = await fetchDataJSON('projects/channels/' + this.$route.params.id + '/info', true);

            this.channel_data = channelRes.data.channel_data;

        });

        if(this.channel_type=='whatsapp'){
            const roomsRes = await fetchDataJSON('projects/channels/' + this.$route.params.id + '/whatsapp/rooms/list', true);

            this.rooms = roomsRes.data.rooms;

            // this.messages = roomsRes.data.channel_messages;
        }

    },
    beforedestroy() {
        this.$eventHub.$off("newCallDeviceRequestEnded");
        this.$eventHub.$off("newWhatsAppMessage");
        this.$eventHub.$off("channelSettingsUpdate");
    },
    methods: {
        async onSearch() {
        this.searchLoading = true;

        this.selectedRoomId = null;

        console.log(this.searchTerm.value);

        const roomsRes = await fetchDataJSON('projects/channels/' + this.$route.params.id + '/whatsapp/rooms/list?term=' + this.searchTerm.value, true);

        this.rooms = roomsRes.data.rooms;

        this.searchLoading = false;
      
    },
        async saveChannelData() {
            this.processing = true;

            const channelRes = await fetchPostJSON('projects/channels/' + this.$route.params.id + '/configure', this.channel_data, true);

            console.log(channelRes);

            this.processing = false;
        },
        async sendChannelListenRequest() {

            if (this.channel_data.channel_type == 'voice' && this.channel_data.channel_provider == 'twilio' && (this.channel_data.account_sid == null || this.channel_data.api_key == null)) {
                this.$notification.error({
                    message: 'Error',
                    description: 'Please set up the necessary credentials first.',
                });
                return;
            }


            this.listenProcessing = true;

            console.log('sendChannelListenRequest');

            this.$eventHub.$emit("newCallDeviceRequest", {
                channel: this.$route.params.id,
                channel_data: this.channel_data,
            });


        },
    },
    data() {
        return {
            channel_data: {},
            general_stats: [],
            loading: true,
            listenProcessing: false,
            name: '',
            description: '',
            channel_type: '',
            processing: false,

            currentUserId: '1234',
            rooms: [],
            messages: [
            

            ],
            roomActions: [
                { name: 'mergeInfo', title: 'Merge Info' },
            ],
            searchLoading: false,

            searchTerm: {
                value: '',
            },
        }
    },
})

</script>

<style lang="scss"></style>